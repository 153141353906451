<template>
  <div class="cityCard">
    <van-nav-bar
      title="景区官方购票"
      @click-left="goback"
    >
      <template #left>
        <van-icon color="#141212"  :size="22" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <iframe :src="src" frameborder="0">

    </iframe>
  </div>
</template>

<script>
  import {Icon, NavBar} from 'vant'
  export default {
    name: "outWeb",
    components:{
      'van-nav-bar':NavBar,
      'van-icon':Icon,
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    data() {
      return {
        src:sessionStorage.getItem('outWeb')
      }
    },
    methods: {
      goback(){
        sessionStorage.removeItem('outWeb');
        this.$router.go(-1)
      }
    }
  }
</script>

<style scoped lang="scss">
  iframe{
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
  }
  .van-nav-bar{
    position: fixed;
    background-color: white;
    width: 100%;
    top: 0;
  }
  .cityCard{
    padding: 46px 0 0 0;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
  }

</style>
